import React, { useCallback, useEffect, useState } from 'react'
import { useEventListener } from 'hooks'
import { CacheStorage } from 'cache-storage'
import { OpenedModals, WaitingModals } from 'modal'


declare global {
  interface Window {
    modalSubscribers: any
    notificationEvents: any
    openedModals: OpenedModals
    waitingModals: WaitingModals
    cacheStorage: CacheStorage
    __DEVICE__: DeviceContext
  }

  type DeviceContext = {
    os: string
    platform: string
    browser: string
    browserVersion: string
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
    isAndroid: boolean
    isBot: boolean
  }
}

export const Context = React.createContext<DeviceContext>(null)

type ProviderProps = {
  value: DeviceContext
}

const Provider: React.FC<ProviderProps> = ({ children, value }) => {
  const [ state, setState ] = useState(value)

  const handler = useCallback(() => {
    const { isMobile, isTablet, isDesktop } = state

    if (!window?.matchMedia) {
      return
    }

    const classes = {
      isMobile: 'mobile',
      isTablet: 'tablet',
      isDesktop: 'desktop',
    }

    const ua = navigator?.userAgent?.toLowerCase()
    const isAndroid = ua.indexOf('android') > -1

    const newState = {
      isAndroid,
      isMobile: window.matchMedia('(max-width: 599px)').matches,
      isTablet: window.matchMedia('(min-width: 600px) and (max-width: 1023px)').matches,
      isDesktop: window.matchMedia('(min-width: 1024px)').matches,
    }

    const isStateChanged = (
      isMobile !== newState.isMobile
      || (isTablet !== newState.isTablet)
      || (isDesktop !== newState.isDesktop)
    )

    if (isStateChanged) {
      const device = Object.keys(newState).find((key) => newState[key])

      Object.keys(classes).forEach((key) => {
        const className = classes[key]

        const html = document.documentElement

        if (key === device) {
          html.classList.add(className)
        }
        else {
          html.classList.remove(className)
        }
      })

      setState({ ...state, ...newState })
    }
  }, [ state ])

  useEffect(() => {
    handler()
  }, [])

  useEventListener('resize', handler)

  return (
    <Context.Provider value={state}>
      {children}
    </Context.Provider>
  )
}


export default Provider
